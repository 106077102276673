import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, makeStyles } from '@material-ui/core';

import NotificationBlock from '../NotificationBlock';
import { ReactComponent as CloseIcon } from '../../../assets/icons/crosses/closeAlt.svg';
import { getDirname } from '../../../utils/parseQuery';
import styles from './styles';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const ErrorWithControl = ({ errorStatus, clearErrorStatus }) => {
  const classes = useStyles();

  const getMessageWithControl = (message = 'Error') => (
    <span className={classes.errorText}>
      <ButtonBase disableRipple className={classes.errorIcon} onClick={clearErrorStatus}>
        <CloseIcon />
      </ButtonBase>
      {message}
    </span>
  );

  return (
    errorStatus && (
      <div className={classes.errorWrap}>
        <NotificationBlock {...errorStatus} message={getMessageWithControl(errorStatus?.message)} />
      </div>
    )
  );
};

ErrorWithControl.propTypes = {
  errorStatus: PropTypes.shape({}),
  clearErrorStatus: PropTypes.func,
};

ErrorWithControl.defaultProps = {
  errorStatus: null,
  clearErrorStatus: PropTypes.func,
};

export default ErrorWithControl;
