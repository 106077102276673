import React, { Suspense, lazy } from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import CustomModal from '../CustomModal';
import useModal from '../../../app/hooks/useModal';
import TERMS_POLICY_MODAL_PROP_TYPES from './types';
import { getDirname } from '../../../utils/parseQuery';
import styles from './styles';
import Loader from '../../UI/Loader';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const TermsPolicyModal = ({ isFooter, isTerms, titleClassName }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { open, handleCloseModal, handleOpenModal } = useModal();

  const Terms = lazy(() => import(`../../../app/intl/translations/${intl.locale}/terms`));
  const Privacy = lazy(() => import(`../../../app/intl/translations/${intl.locale}/privacy`));

  return (
    <>
      <ButtonBase
        onClick={handleOpenModal}
        className={cn(classes.titleLink, { [classes.footerLink]: isFooter }, titleClassName)}
        disableRipple
      >
        <FormattedMessage
          id={isTerms ? 'common.termsModal.triggerBtn' : 'common.privacyModal.triggerBtn'}
        />
      </ButtonBase>

      <CustomModal
        handleClose={handleCloseModal}
        isOpen={open}
        titleId={isTerms ? 'common.termsModal.title' : 'common.privacyModal.title'}
        cancelText="common.termsModal.closeBtn"
        onlyClose
        contentClass={classes.modalContent}
      >
        <div className={classes.content}>
          <Suspense fallback={<Loader />}>{isTerms ? <Terms /> : <Privacy />}</Suspense>
        </div>
      </CustomModal>
    </>
  );
};

TermsPolicyModal.propTypes = TERMS_POLICY_MODAL_PROP_TYPES.RULES;

TermsPolicyModal.defaultProps = TERMS_POLICY_MODAL_PROP_TYPES.DEFAULTS;

export default TermsPolicyModal;
