import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, makeStyles } from '@material-ui/core';

import Avatar from '../Avatar';
import styles from './styles';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const ProfileIdentityItem = ({ photo, email, name, size }) => {
  const classes = useStyles({ size });

  return (
    <ListItem component="div" className={classes.wrap}>
      <div className={classes.avatarWrap}>
        <Avatar photo={photo} name={name} size={size} />
      </div>
      <div>
        <div className={classes.name}>{name}</div>
        <div className={classes.email}>{email}</div>
      </div>
    </ListItem>
  );
};

ProfileIdentityItem.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  photo: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

ProfileIdentityItem.defaultProps = {
  photo: null,
  size: 'medium',
};

export default ProfileIdentityItem;
