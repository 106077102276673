import PropTypes from 'prop-types';

export const productPropTypes = PropTypes.shape({
  clientId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const connectedProductPropTypes = PropTypes.arrayOf(productPropTypes.isRequired);

export const userProfilePropTypes = PropTypes.shape({
  connectedProducts: connectedProductPropTypes.isRequired,
  country: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  profilePhoto: PropTypes.string,
  registeredBy: PropTypes.string.isRequired,
  sub: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  zoneInfo: PropTypes.string.isRequired,
});

export const userProfileDefaultPropTypes = PropTypes.shape({
  country: '',
  phoneNumber: '',
  profilePhoto: '',
});
