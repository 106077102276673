import { textMixin, textSizes } from '../../../app/theme/mixins';

export default function styles(theme) {
  return {
    wrap: {
      padding: '0',
    },
    avatarWrap: {
      marginRight: '12px',
    },
    name: ({ size }) => ({
      ...textMixin({
        color: theme.custom.darkColor,
        opacity: 1,
        size: size !== 'large' ? textSizes[size] : textSizes.medium,
      }),
      fontWeight: '600',
      wordBreak: 'break-all',
      marginBottom: '4px',
    }),
    email: {
      ...textMixin({
        color: theme.custom.textColor,
        opacity: 0.6,
        size: textSizes.small,
      }),
      wordBreak: 'break-all',
    },
  };
}
