import apiReqSagaCreator from '../apiReqSagaCreator';
import API_REQ from '../apiActions';
import { ApiPaths, HTTP_METHODS } from '../apiConstants';

const { REACT_APP_API_URL: baseURL = '' } = process.env;

const Auth = {
  createSession: apiReqSagaCreator(
    API_REQ.AUTH.CREATE_SESSION,
    (params) => ({
      url: ApiPaths.Auth.CreateSession,
      method: HTTP_METHODS.GET,
      params,
    }),
    {
      authRequired: false,
    },
  ),
  checkSession: apiReqSagaCreator(
    API_REQ.AUTH.CHECK_SESSION,
    () => ({
      url: ApiPaths.Auth.CheckSession,
      method: HTTP_METHODS.GET,
    }),
    {
      authRequired: false,
    },
  ),
  login: apiReqSagaCreator(
    API_REQ.AUTH.LOGIN,
    (data) => ({
      url: ApiPaths.Auth.Login,
      method: HTTP_METHODS.POST,
      data,
    }),
    {
      authRequired: false,
    },
  ),
  MFA: {
    login: apiReqSagaCreator(
      API_REQ.AUTH.MFA.LOGIN,
      (data) => ({
        url: ApiPaths.Auth.MFA.Login,
        method: HTTP_METHODS.POST,
        data,
      }),
      {
        authRequired: false,
      },
    ),
    recoveryLogin: apiReqSagaCreator(
      API_REQ.AUTH.MFA.RECOVERY_LOGIN,
      (data) => ({
        url: ApiPaths.Auth.MFA.RecoveryLogin,
        method: HTTP_METHODS.POST,
        data,
      }),
      {
        authRequired: false,
      },
    ),
  },
  logout: apiReqSagaCreator(API_REQ.AUTH.LOGOUT, (data) => ({
    url: ApiPaths.Auth.Logout,
    method: HTTP_METHODS.POST,
    data,
  })),
  verifyEmail: apiReqSagaCreator(
    API_REQ.AUTH.VERIFY_EMAIL,
    (data) => ({
      url: ApiPaths.Auth.VerifyEmail,
      method: HTTP_METHODS.POST,
      data,
    }),
    {
      authRequired: false,
    },
  ),
  resendCode: apiReqSagaCreator(
    API_REQ.AUTH.RESEND_CODE,
    (data) => ({
      url: ApiPaths.Auth.ResendCode,
      method: HTTP_METHODS.POST,
      data,
    }),
    {
      authRequired: false,
    },
  ),
  connect: apiReqSagaCreator(API_REQ.AUTH.CONNECT, (data) => ({
    url: ApiPaths.Auth.Connect,
    method: HTTP_METHODS.POST,
    data,
  })),
  getConnectionStatus: apiReqSagaCreator(API_REQ.AUTH.GET_CONNECTION_STATUS, ({ clientId }) => ({
    url: `${ApiPaths.Auth.GetConnectionStatus}/${clientId}`,
    method: HTTP_METHODS.GET,
  })),
  refreshToken: apiReqSagaCreator(
    API_REQ.AUTH.REFRESH_TOKEN,
    (data) => ({
      url: ApiPaths.Auth.Token,
      method: HTTP_METHODS.POST,
      data,
    }),
    {
      authRequired: false,
      ignorePendingExchange: true,
      maxRetries: 0,
    },
  ),
  signUp: apiReqSagaCreator(
    API_REQ.AUTH.SIGN_UP,
    (data) => ({
      url: ApiPaths.Auth.SignUp,
      method: HTTP_METHODS.POST,
      data,
    }),
    {
      authRequired: false,
    },
  ),
  consent: () => baseURL + ApiPaths.Auth.Consent,
  consentLogged: (token) => `${baseURL}${ApiPaths.Auth.ConsentLogged}?refresh-token=${token}`,
  forgotPassword: apiReqSagaCreator(
    API_REQ.AUTH.FORGOT_PASSWORD,
    (data) => ({
      url: ApiPaths.Auth.ForgotPassword,
      method: HTTP_METHODS.POST,
      data,
    }),
    {
      authRequired: false,
    },
  ),
  confirmPassword: apiReqSagaCreator(
    API_REQ.AUTH.CONFIRM_PASSWORD,
    (data) => ({
      url: ApiPaths.Auth.ConfirmPassword,
      method: HTTP_METHODS.POST,
      data,
    }),
    {
      authRequired: false,
    },
  ),
  validatePassword: apiReqSagaCreator(API_REQ.AUTH.VALIDATE_PASSWORD, (data) => ({
    url: ApiPaths.Auth.ValidatePassword,
    method: HTTP_METHODS.POST,
    data,
  })),
};

export default Auth;
