import React, { useMemo } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UnAuthorizeLayout from '../layouts/UnAuthorizeLayout';
import RoutePaths from './routePaths';
import AuthSelectors from '../../pages/Auth/selectors';
import useProductType from '../hooks/useProductType';
import RouteWrapper from './RouteWrapper';

function UnauthorizedRoute(props) {
  const isSignedIn = useSelector(AuthSelectors.getIsSigned());
  const { state } = useLocation();
  const { isExternalProduct, isInternalProduct } = useProductType();

  const cameFromUserProfilePage = useMemo(
    () => state?.from === RoutePaths.UserProfile._(),
    [state],
  );

  if (isSignedIn && !cameFromUserProfilePage && !isExternalProduct && !isInternalProduct)
    return <Redirect to={RoutePaths.UserProfile._()} />;

  return (
    <UnAuthorizeLayout>
      <RouteWrapper centerLoader>
        <Route {...props} />
      </RouteWrapper>
    </UnAuthorizeLayout>
  );
}

export default UnauthorizedRoute;
