import Cookies from 'universal-cookie';

const cookie = new Cookies();

const getCookieDomain = () => {
  const [topLevel, root] = window.location.hostname.split('.').reverse();
  return `.${root}.${topLevel}`;
};

const options = {
  path: '/',
  domain: getCookieDomain(),
  sameSite: 'lax',
  secure: true,
};

const setCookie = (key, value) => {
  cookie.set(key, value, options);
};

const getCookie = (key) => cookie.get(key, options);

export { setCookie, getCookie };
