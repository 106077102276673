import { call, delay, put, select } from 'redux-saga/effects';
import RoutePaths from '../../app/routes/routePaths';
import AuthSelectors from '../../pages/Auth/selectors';
import API_REQ from '../apiActions';
import Auth from '../apiEndpoints/Auth';
import fetchUserProfile from './fetchUserProfile';
import redirect from '../utils/redirect';
import cache from '../utils/cache';

export default function* consentLogin() {
  const isRAKPlatform = yield select(AuthSelectors.isRAKPlatform());
  const refreshToken = cache.getRefreshToken();

  // Login directly for RAK ID portal
  if (isRAKPlatform) {
    yield call(fetchUserProfile);
    redirect(RoutePaths.UserProfile._());
    return;
  }

  yield put(API_REQ.AUTH.CACHED_TOKEN_VERIFIED(false));

  const consentRequestUrl = refreshToken ? Auth.consentLogged(refreshToken) : Auth.consent();

  window.location.replace(consentRequestUrl);

  yield delay(5000);

  yield put(API_REQ.AUTH.CACHED_TOKEN_VERIFIED(true));
}
