const PARAM_PREFIX = ':';

function pathCreator(path) {
  return function pathGenerate(params = {}) {
    return Object.entries(params || {}).reduce(
      (resultPath, [key, value]) =>
        resultPath.replace(new RegExp(`${PARAM_PREFIX}${key}`), value.toString()),
      path,
    );
  };
}

const RoutePaths = {
  ForceLogin: {
    _: pathCreator('/force-login'),
  },
  Auth: {
    DEFAULT: '/o-auth',
    Login: {
      _: pathCreator('/o-auth/login'),
      Identity: {
        _: pathCreator('/o-auth/login/identity'),
      },
    },
    VerifyEmail: {
      _: pathCreator('/o-auth/verify-email/:type'),
    },
    Connect: {
      _: pathCreator('/o-auth/connect'),
    },
    Registration: {
      _: pathCreator('/o-auth/registration'),
    },
    ForgotPassword: {
      _: pathCreator('/o-auth/forgot-password'),
    },
    ConfirmPassword: {
      _: pathCreator('/o-auth/confirm-password'),
    },
    AuthError: {
      _: pathCreator('/o-auth/auth-error'),
    },
  },
  UserProfile: {
    _: pathCreator('/profile'),
    Products: pathCreator('/products'),
    Security: pathCreator('/security'),
  },
  Single: {
    About: pathCreator('/about'),
    ConnectZendesk: pathCreator('/connect-zendesk'),
  },
};

export default RoutePaths;
